import React from 'react'
import { Link as RouterLink } from 'gatsby'

import { Section, Container, Row, Column, Box, Link } from 'components'

import { useSharedState } from 'context/sharedState'

const Links = () => {
  const [state] = useSharedState()
  const entryPath = state.entryPath || '/'

  const links = [
    {
      to: '/ratgeber/t/teilverkauf',
      children: 'Ratgeber',
    },
    {
      to: '/faq/haeufige-fragen',
      children: 'Häufige Fragen',
    },
    {
      to: `${entryPath}#so-funktioniert-es`,
      children: "So funktioniert's",
    },
  ]

  return (
    <Section>
      <Container>
        <Row>
          {links.map(({ to, children }, idx) => (
            <Column key={idx} size={[1, null, 1 / 3, 1 / 3]}>
              <Link
                as={RouterLink}
                to={to}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 3,
                  color: 'colorBlue50',
                  textDecoration: 'none',
                  justifyContent: ['flex-start', 'center'],
                  width: ['100%', 'auto'],
                  py: 3,
                  transition: 'color 100ms ease',
                  '&:hover': {
                    color: 'text',
                  },
                }}
              >
                {children}
                <Box
                  as="svg"
                  viewBox="0 0 15 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    width: 15,
                    height: 11,
                    ml: 4,
                  }}
                >
                  <path
                    fill="currentColor"
                    d="M14 6l-4.5 4.5c-.1.2-.3.3-.5.3s-.4-.1-.5-.3a.7.7 0 010-1l3.2-3.3H1.5c-.4 0-.8-.3-.8-.7 0-.5.4-.8.8-.8h10.2L8.5 1.5a.7.7 0 010-1c.3-.3.7-.3 1 0L14 5l.2.2v.6L14 6z"
                  />
                </Box>
              </Link>
            </Column>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default Links
